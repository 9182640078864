import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = { class: "w-100" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "form-text text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MandatoryFieldFlag = _resolveComponent("MandatoryFieldFlag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _createTextVNode(_toDisplayString(_ctx.label), 1),
            (_ctx.required)
              ? (_openBlock(), _createBlock(_component_MandatoryFieldFlag, { key: 0 }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.readonly ? 'p' : 'input'), {
        class: "form-control",
        type: _ctx.type,
        value: _ctx.value,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onInput($event))),
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        "aria-label": _ctx.label ? _ctx.label : '',
        required: _ctx.required,
        maxLength: _ctx.maxLength,
        pattern: _ctx.pattern,
        tabIndex: _ctx.tabIndex,
        ref: "input",
        onInvalid: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validityMessage ? _ctx.setValidityMessage(_ctx.validityMessage) : null))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.value), 1)
        ]),
        _: 1
      }, 8, ["type", "value", "placeholder", "disabled", "aria-label", "required", "maxLength", "pattern", "tabIndex"]))
    ]),
    (_ctx.helpText)
      ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.helpText), 1))
      : _createCommentVNode("", true)
  ]))
}