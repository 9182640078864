import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2355c6c3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "page-heading" }
const _hoisted_3 = { class: "form-container" }
const _hoisted_4 = { class: "text-input-container" }
const _hoisted_5 = ["disabled", "aria-label"]
const _hoisted_6 = {
  key: 0,
  class: "search-results"
}
const _hoisted_7 = { class: "page-heading" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { key: 3 }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["aria-label", "onClick"]
const _hoisted_16 = {
  key: 2,
  class: "wrap-content"
}
const _hoisted_17 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t("tuition-fees.mirror-user")), 1),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_TextInput, {
            class: "mr-2 search-input",
            ref: "searchInput",
            placeholder: _ctx.$t('tuition-fees.search-on') + '...',
            label: 
              _ctx.$t('tuition-fees.search-term') +
              ' (' +
              _ctx.$t('tuition-fees.minimum-char') +
              ')'
            ,
            required: false,
            value: this.searchTerm,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((this.searchTerm) = $event)),
            onKeyup: _withKeys(_ctx.searchUsers, ["enter"])
          }, null, 8, ["placeholder", "label", "value", "onKeyup"]),
          _createElementVNode("button", {
            id: "fetchBtn",
            class: "btn btn-primary",
            disabled: _ctx.searchButtonDisabled,
            "aria-label": _ctx.$t('shared.search'),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchUsers()))
          }, _toDisplayString(_ctx.$t("shared.search")), 9, _hoisted_5)
        ])
      ])
    ]),
    (_ctx.searchResults.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("h2", _hoisted_7, _toDisplayString(_ctx.$t("tuition-fees.search-results")), 1),
          _createVNode(_component_Table, {
            rows: _ctx.searchResults,
            overrideColWidthCalculation: true,
            id: "search-result-table"
          }, {
            td: _withCtx((data) => [
              (data.col === 'displayName')
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t("person.name")), 1))
                : _createCommentVNode("", true),
              (data.col === 'luEduPersonPrimaryId')
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t("person.lu-account")), 1))
                : _createCommentVNode("", true),
              (data.col === 'personalId')
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.$t("person.personalId")), 1))
                : _createCommentVNode("", true),
              (data.col === 'email')
                ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(_ctx.$t("person.email-address")), 1))
                : _createCommentVNode("", true),
              (data.col === 'links')
                ? (_openBlock(), _createElementBlock("span", _hoisted_12))
                : _createCommentVNode("", true)
            ]),
            default: _withCtx((data) => [
              (data.col === 'luEduPersonPrimaryId' || data.col === 'email')
                ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(data.val ? data.val : _ctx.$t("tuition-fees.unknown")), 1))
                : _createCommentVNode("", true),
              (data.col === 'links')
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, [
                    _createElementVNode("button", {
                      id: "mirrorBtn",
                      class: "btn btn-outline-primary",
                      "aria-label": _ctx.$t('tuition-fees.mirrow'),
                      onClick: ($event: any) => (
                _ctx.onMirrorClicked(
                  data.row.personalId,
                  data.row.displayName,
                  data.row.luEduPersonPrimaryId
                )
              )
                    }, _toDisplayString(_ctx.$t("tuition-fees.mirror")), 9, _hoisted_15)
                  ]))
                : (data.col === 'displayName')
                  ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(data.val), 1))
                  : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["rows"])
        ]))
      : (_ctx.searchDone && _ctx.searchResults.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("tuition-fees.no-search-results")), 1)
          ]))
        : _createCommentVNode("", true)
  ]))
}